<template>
  <vue-typeahead-bootstrap
    :value="value"
    @input=onSearchPWS($event)
    :data="pws_list"
    @hit="setPWS($event)"
    :minMatchingChars="3"
  />
</template>

<script>
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap"
import { debounce } from "lodash"


export default {
  props: { value: { type: String } },
  data: function() {
    return {
      pws_list: [],
      pws_id: '',
      pws_name: ''
    }
  },
  components: {
    VueTypeaheadBootstrap
  },
  methods: {
    onSearchPWS: async function(value) {
      this.$emit('input', value)
      if (value.length < 3) {
        return
      }
      await this.searchPWS(value)
    },
    searchPWS: debounce(async function(value) {
      console.log('searching ' + value)
      console.log(value)
      const path = process.env.VUE_APP_API_URI + "/search-pws"
      try {
        const res = await this.axios.get(
          path, { withCredentials: true, params: {search_text: value} }
        )
        if (res.data) {
          this.pws_list = res.data
        } else {
          console.log("no water systems found")
        }
      } catch(error) {
        console.error(error)
      }
    }, 500),
    setPWS(pws_text) {
      //console.log('called setPWS')
      const pws_array = pws_text.split(',') 
      this.pws_id = pws_array[0]                                       
      this.pws_name = pws_array[1]
      this.$emit("hit", {pws_id: this.pws_id, pws_name: this.pws_name})
    },
  }
}
</script>
