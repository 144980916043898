<template>
  <div>
    <h3 class="mt-4 mb-4">Change Water System</h3>
    <div v-if="$store.state.role != 'MSDH'">
      <p>Select Water System</p>
      <b-form-select
        @change="setPWS"
        v-model="selected_pws_id"
        :options="pws_options"
      ></b-form-select>
    </div>
    <div v-if="$store.state.role == 'MSDH'">
      <p>Search and Select Water System</p>
      <pws-auto-complete
        class="mt-3"
        v-model="pws_text"
        @hit="onPWSSearchHit($event)"
        @change="clearMessage"
      />
    </div>
    <b-alert class="mt-3" v-model="show_notice" variant="info" dismissible
    >PWS Changed to {{ pws_id }} - {{ pws_name }}. &nbsp;<b-link to="/">Back to Main Screen</b-link></b-alert
    >
  </div>
</template>
<script>
// @ is an alias to /src
import PwsAutoComplete from "@/components/PwsAutoComplete.vue"

export default {
  name: "ChangeWaterSystem",
  data() {
    return {
      selected_pws_id: null,
      show_notice: false,
      pws_text: "",
      pws_id: "",
      pws_name: "",
    }
  },
  components: {
    PwsAutoComplete
  },
  mounted() {
    this.selected_pws_id = this.$store.state.pws_id
  },
  computed: {
    pws_options() {
      const all_pws = this.$store.getters.water_systems
      let options = []
      for (const [pws_id, value] of Object.entries(all_pws)) {
        const pws_name = value.pws_name
        options.push({ value: pws_id, text: pws_id + " " + pws_name })
      }
      return options
    }
  },
  methods: {
    clearMessage() {
      console.log('got change event')
      this.show_notice = false
    },
    setPWS() {
      const pws_id = this.selected_pws_id
      const all_pws = this.$store.getters.water_systems
      const pws = all_pws[pws_id]
      console.log('setting ' + pws)
      console.log(pws)
      this.$store.commit("set_pws", pws)
      this.show_notice = true
      this.pws_id = pws_id
      this.pws_name = pws.pws_name
    },
    onPWSSearchHit(value) {
      console.log("PWS Selected: " + value.pws_id)
      this.pws_id = value.pws_id
      this.pws_name = value.pws_name
      this.show_notice = true
      this.$store.commit("set_pws", value)
    }
  }
}
</script>
